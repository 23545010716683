import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from 'react';

import "./App.css";
import Navbar from "./Components/Navbar";
import Homedepartment from "./Components/Homedepartment";
import Homeadavntage from "./Components/Homeadavntage";
import Homeadvanbelow from "./Components/Homeadvanbelow";
import Homecounterup from "./Components/Homecounterup";
import Homecollab from "./Components/Homecollab";
import Homeeducationfor from "./Components/Homeeducationfor";
import Hometopfaculty from "./Components/Hometopfaculty";
import Homegetlatest from "./Components/Homegetlatest";
import Footer from "./Components/Footer";
import Fundanibanner from "./Components/Fundamentalanima/Fundanibanner";
import Fundanifooter from "./Components/Fundamentalanima/Fundanifooter";
import Enterbanner from "./Components/Entrepreneurship/Enterbanner";
import Graphybanner from "./Components/Videography/Graphybanner";
import Visualbanner from "./Components/VisualDevelopment/Visualbanner";
import Motionbanner from "./Components/Motiondesign/Motionbanner";
import Architecbanner from "./Components/Architecture Visualization/Architecbanner";
import Advancedigibanner from "./Components/Advancedigitalmarket/Advancedigibanner";
import Socialmediabanner from "./Components/Socialmediamarket/Socialmediabanner";
import Abaout from "./Components/Abaout";
import Leadership from "./Components/Leadership";
import VissionMission from "./Components/VissionMission";
import Yogabanner from "./Components/Yoga/Yogabanner";
import Riskbanner from "./Components/Riskmangement/Riskbanner";
import Dietbanner from "./Components/Diet&Nutrition/Dietbanner";

import Contactus from "./Components/Contactus";
import Privacypolicy from "./Components/Privacypolicy";
import Becomeaninstructor from "./Components/Becomeaninstructor";
import HomeReasonsToJoin from "./Components/HomeReasonsToJoin";
import Fundamenbanner from "./Components/Fundamentaldigimarket/Fundamenbanner";
import Poups from "./Components/Poups";
import Sliders from "./Components/Sliders";
import Notices from "./Notices";
import Menues from "./Menues";
import HomeCoursePopular from "./Components/HomeCoursePopular";
import AnimationCourse from "./Components/AnimationCourse";
import MarketCourse from "./Components/MarketCourse";
import EntrepreneurshipCourse from "./Components/EntrepreneurshipCourse";
import CourseYoga from "./Components/CourseYoga";
import Form from "./Components/Form";
import Brochureenquiry from "./Components/Brochureenquiry";
import Popup from "./Components/Popup";
import Dummypop from "./Components/Dummypop";
import HomeExploreProgram from "./Components/HomeExploreProgram";
import HomeBannerII from "./Components/HomeBannerII";
import AllCoursepage from "./Components/AllCoursepage";
import Homestudenttestemonial from "./Components/Homestudenttestemonial";
import Homeourlearnerswork from "./Components/Homeourlearnerswork";
import Homecontactsupport from "./Components/Homecontactsupport";
import Homecorecredentials from "./Components/Homecorecredentials";
import Banner from "./Components/Aiindigitalmarketing/Banner";
import HomeBatchTime from "./Components/HomeBatchTime";
import Pouup1 from "./Components/Pouup1";
import DateComponent from "./Components/DateComponent";
import Faq from "./Components/Faq";
function App() {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 5000);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);
  return (
    <>
      <Router>
      {showPopup && <Pouup1 onClose={() => setShowPopup(false)} />}
        <Menues />
        <Navbar />
        <Routes>
          <Route
            exact
            path="/"
            element={
              <>
                {" "}
              <Notices /> <HomeBannerII /> <HomeExploreProgram />{" "}
                <Homeeducationfor /> <HomeBatchTime /> <Homeourlearnerswork />{" "}
                <Homedepartment /> <Homecontactsupport /> <Homeadavntage />
                <Homeadvanbelow /> <Homecounterup /> <Homecorecredentials />
                <HomeReasonsToJoin />
                <HomeCoursePopular />
                <Hometopfaculty /><Faq/><Homecollab />
                <Homegetlatest />
              </>
            }
          ></Route>
          <Route
            exact
            path="/Dummypop"
            element={
              <>
                {" "}
                <Dummypop />
              </>
            }
          ></Route>
          <Route
            exact
            path="/AnimationCourse"
            element={
              <>
                {" "}
                <AnimationCourse />
              </>
            }
          ></Route>
          <Route
            exact
            path="/DateComponent"
            element={
              <>
                {" "}
                <DateComponent/>
              </>
            }
          ></Route>
          <Route
            exact
            path="/all-course"
            element={
              <>
                {" "}
                <AllCoursepage />
              </>
            }
          ></Route>

          <Route
            exact
            path="/Brochure"
            element={
              <>
                {" "}
                <Brochureenquiry />
              </>
            }
          ></Route>

          <Route
            exact
            path="/Form"
            element={
              <>
                {" "}
                <Form />
              </>
            }
          ></Route>

          <Route
            exact
            path="/Popup"
            element={
              <>
                {" "}
                <Popup />
              </>
            }
          ></Route>

          <Route
            exact
            path="/CourseYoga"
            element={
              <>
                {" "}
                <CourseYoga />
              </>
            }
          ></Route>

          <Route
            exact
            path="/MarketCourse"
            element={
              <>
                {" "}
                <MarketCourse />
              </>
            }
          ></Route>

          <Route
            exact
            path="/EntrepreneurshipCourse"
            element={
              <>
                {" "}
                <EntrepreneurshipCourse />
              </>
            }
          ></Route>

          <Route
            exact
            path="/About"
            element={
              <>
                {" "}
               <Abaout />
              </>
            }
          ></Route>

          <Route
            exact
            path="/Contactus"
            element={
              <>
                {" "}
                <Contactus />
              </>
            }
          ></Route>



          <Route
            exact
            path="/Leadership"
            element={
              <>
                {" "}
                <Leadership />
              </>
            }
          ></Route>

          <Route
            exact
            path="/Vision&Mission"
            element={
              <>
                {" "}
                <VissionMission />
              </>
            }
          ></Route>

          <Route
            exact
            path="/fundamental-of-3D-animation"
            element={
              <>
                {" "}
                <Fundanibanner />
                <Fundanifooter />
              </>
            }
          ></Route>
          <Route
            exact
            path="/Entrepreneurship"
            element={
              <>
                {" "}
                <Enterbanner />
                <Fundanifooter />
              </>
            }
          ></Route>

          <Route
            exact
            path="/Videography"
            element={
              <>
                {" "}
                <Graphybanner />
                <Fundanifooter />
              </>
            }
          ></Route>

          <Route
            exact
            path="/certification-in-maharishi-yoga"
            element={
              <>
                {" "}
                <Yogabanner />
                <Fundanifooter />
              </>
            }
          ></Route>

          <Route
            exact
            path="/maharishi-diet-and-nutrition"
            element={
              <>
                {" "}
                <Dietbanner />
                <Fundanifooter />
              </>
            }
          ></Route>

          <Route
            exact
            path="/Risk-Management"
            element={
              <>
                {" "}
                <Riskbanner />
                <Fundanifooter />
              </>
            }
          ></Route>

          <Route
            exact
            path="/visual-development-concept-art-advanced"
            element={
              <>
                {" "}
                <Visualbanner />
                <Fundanifooter />
              </>
            }
          ></Route>

          <Route
            exact
            path="/motion-design-for-graphic-designers"
            element={
              <>
                {" "}
                <Motionbanner />
                <Fundanifooter />
              </>
            }
          ></Route>

          <Route
            exact
            path="/Architecture-visualization"
            element={
              <>
                {" "}
                <Architecbanner />
                <Fundanifooter />
              </>
            }
          ></Route>

          <Route
            exact
            path="/advance-digital-marketing-course"
            element={
              <>
                {" "}
                <Advancedigibanner />
                <Fundanifooter />
              </>
            }
          ></Route>

          <Route
            exact
            path="/Ai-in-digital-Marketing"
            element={
              <>
                {" "}
                <Banner />
                <Fundanifooter />
              </>
            }
          ></Route>

          <Route
            exact
            path="/digital-marketing-course"
            element={
              <>
                {" "}
                <Fundamenbanner />
                <Fundanifooter />
              </>
            }
          ></Route>

          <Route
            exact
            path="/Social-Media-Marketing"
            element={
              <>
                {" "}
                <Socialmediabanner />
                <Fundanifooter />
              </>
            }
          ></Route>

          <Route
            exact
            path="/Privacypolicy"
            element={
              <>
                {" "}
                <Privacypolicy />
              </>
            }
          ></Route>

          <Route
            exact
            path="/Becomeaninstructor"
            element={
              <>
                {" "}
                <Becomeaninstructor />
              </>
            }
          ></Route>
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
